<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <c-text
            :required="true"
            label="화학물질명/CAS.NO"
            name="searchNm"
            placeholder="화학물질명/CAS.NO/영문명/물질명요약"
            v-model="searchParam.searchNm">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="materialTable"
      title="화학물질 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="mttrid"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
export default {
  name: 'material-api-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
        plantCd: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'mttrnmkor',
            field: 'mttrnmkor',
            label: '화학물질명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'mttrnmeng',
            field: 'mttrnmeng',
            label: '화학물질명(영문)',
            align: 'left',
            sortable: true,
          },
          {
            name: 'casno',
            field: 'casno',
            label: 'CAS 번호',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'molecform',
            field: 'molecform',
            label: '분자식',
            align: 'center',
            style: 'width:130px',
            sortable: true,
          },
        ],
        data: [],
        height: '100%'
      },
      searchParam: {
        searchNm: '',
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
    },
    getList() {
      if (this.searchParam.searchNm.length < 2) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '검색어를 2단어 이상 입력하세요(CAS번호/물질명).', // 자재를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = '/api/openapi/materials';
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data.items;
        },);
      }
    },
    select() {
      let selectData = this.$refs['materialTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '화학물질을 선택하세요.', // 자재를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = '/api/openapi/materials/koshainfo';
        this.$http.type = 'PUT';
        this.$http.param = selectData;
        this.$http.request((_result) => {
          this.$emit('closePopup', _result.data);
        },);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
